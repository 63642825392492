import * as React from "react"
import { Stack } from "/src/components/grid"
import * as Text from "/src/components/text"
import Header from "/src/components/header"
import styled from "styled-components"
import { Colors } from "/src/components/palette"
import ukrposhtamini from "/src/images/logos/ukrposhtamini.png"
import diiamini from "/src/images/logos/diiamini.png"
import { useWindowSize } from "/src/hooks"
import Seo from "../components/seo"
import _ from "lodash"
import { Chevron } from "/src/components/svgs"

const TextAreaStyled = styled.div`
border-radius: 16px;
box-sizing: border-box;
padding: 9px;
display: flex;
align-items: center;

border: ${({ focus }) => focus
        ? "1px solid #000"
        : "1px solid #F5F5F5"};

background: #F5F5F5;
transition: all 0.5s ease;
overflow: hidden;
input {
    outline: none;
    resize: none;
    font-size: 16px;
    border: none;
    background: none;
    width: 100%;
    margin: 0;
    padding: "0px";
    display: block;
    height: 3rem;
}
`

const FieldContainer = styled.div`
background: ${({ focus }) => focus ? "#DEDEDE" : "#DEDEDE00"};
border-radius: 16px;
`

const TextArea = ({ label, value, error, helper, oneliner, handleBlur, ...props }) => {
    const [focus, setFocus] = React.useState()
    const ref = React.useRef()

    return (
        <FieldContainer {...{
            focus,
            onClick: () => { ref.current.focus() }
        }}>
            <TextAreaStyled {...{ focus, oneliner, hasValue: !!value, hasError: !!error }}>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: "0 16px", width: 19 }}>
                    <path d="M13.5 13.5L17.5 17.5" stroke="black" stroke-width="2" />
                    <circle cx="8" cy="8" r="7" stroke="black" stroke-width="2" />
                </svg>

                <input
                    type="text"
                    placeholder="Пошук"
                    {
                    ...{
                        ...props,
                        value: value || "",
                        onFocus: (e) => {
                            setFocus(true)
                        },
                        onBlur: (e) => {
                            _.isFunction(handleBlur) && handleBlur(e)
                            _.delay(function (text) {
                                setFocus(false)
                            }, 200, 'later');
                        },
                    }
                    }
                    ref={ref}
                />
            </TextAreaStyled>
        </FieldContainer>
    )
}


const SelectContainer = styled.div`

    background: #F5F5F5;
    border: 0;
    border-radius: 16px;
    position: relative;
    label {
        padding: 22px 0 22px 22px;
        display: block;
        font-size: 16px;
        color: rgba(0,0,0,.5);
        transition: all 0.5s ease;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    .value{
        margin: 0 24px 0 24px;
    }
`

const SelectField = styled.select`
    padding: 0 24px;
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;

`

const Select = ({ options = [], label, value, error, helper, handleBlur, onChange, ...props }) => {
    const [focus, setFocus] = React.useState()
    const ref = React.useRef()
    return (
        <FieldContainer {...{
            focus,
            onClick: () => { ref.current.focus() }
        }}>
            <SelectContainer {...{ focus, hasValue: !!value }} onClick={() => { ref.current.focus() }}>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <label style={{ color: value ? "#000" : "rgba(0,0,0,.5)", flexShrink: 1 }}>{value ? value : label}</label>
                    <div style={{
                        padding: "12px"
                    }}>
                        <Chevron {...{ width: 18, height: 10, direction: focus ? "up" : "down" }} />
                    </div>
                </div>

                <div style={{ position: "absolute", width: "100%", top: 0, bottom: 0, height: "100%" }}>

                    <SelectField {...{
                        value,
                        ref,
                        ...props,
                        onChange: (e) => {
                            onChange(e)
                            ref.current.blur()
                        },
                        onFocus: () => { setFocus(true) },
                        onBlur: (e) => {
                            _.isFunction(handleBlur) && handleBlur(e)
                            _.delay(() => {
                                setFocus(false)
                            }, 200, 'later');
                        },
                    }}
                    >
                        <option value="" disabled></option>
                        {options.map(({ text, value }) => {
                            return (
                                <option value={value}>{text}</option>
                            )
                        })}
                    </SelectField>
                </div>
            </SelectContainer>
        </FieldContainer>
    )
}




const CheckboxFieldContainer = styled.div`
    display: flex;
    background: #fff;
    padding: 0;
    border-radius: 16px;
    label {
        color: rgba(0,0,0,1);
    }
    input[type="checkbox"] {
        height: 24px;
        width: 24px;
        appearance: none;
        background: #F5F5F5;
        border-radius: 8px;
    }
    input[type="checkbox"]:hover {
        background: #fff;
        border: 1px solid rgba(0,0,0,.5);
    }
    input[type="checkbox"]:checked {
        background: #000;
        background: center no-repeat url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12.5L1 6.5L6 8.5L13.5 1L6 12.5Z" fill="white" stroke="white"/></svg>') #000;
    }
`

export const Checkbox = ({ label, ...props }) => {
    return (
        <CheckboxFieldContainer>
            <div style={{ marginRight: 16 }}>
                <input type="checkbox" {...props} defaultChecked={props.value} checked={props.value} />
            </div>
            <label>
                {label}
            </label>
        </CheckboxFieldContainer>
    )
}





const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    @media(max-width: 1279px) {
        max-width: 1024px;
    }
`
const regionOptions = [
    "м. Київ",
    "Одеська область",
    "Дніпровська область",
    "Чернігівська область",
    "Харківська область",
    "Житомирська область",
    "Полтавська область",
    "Херсонська область",
    "Київська область",
    "Запорізька область",
    "Луганська область",
    "Донецька область",
    "Вінницька область",
    "Автономна Республіка Крим",
    "Миколаївська область",
    "Кіровоградська область",
    "Сумська область",
    "Львівська область",
    "Черкаська область",
    "Хмельницька область",
    "Волинська область",
    "Рівненська область",
    "Івано-Франківська область",
    "Тернопільська область",
    "Закарпатська область",
    "Чернівецька область",
    "Всі області"
]


const mainProductOptions = [
    "Одяг або взуття",
    "Прикраси",
    "Декор для дому",
    "Посуд та кухонні пристосування",
    "Іграшки",
    "Спортивне спорядження",
    "Електроніку",
    "Косметика",
    "Меблі",
    "Інше",
    "Всі індустрії"
]



const winnersData = [
    {
        "id": 65,
        "brand_name": "She flowers",
        "website": "https://www.instagram.com/she.flowers.ua/",
        "location": "Одеська область",
        "main_product": "Декор для дому",
        "about": "She flowers - бренд з України, що виробляє декор для дому із сухоцвітів та стабілізованих квітів. Це екологічний, естетичний та емоційний декор. Композиції, букети для наречених, свічки, дифузори. За 4 роки ми закохали українців у стабілізовані квіти та відкрили перший в Україні магазин сухоцвітів. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 75,
        "brand_name": "Gushka Wool",
        "website": "https://gushka.ua/en-gb/shop/",
        "location": "Івано-Франківська область",
        "main_product": "Декор для дому",
        "about": "Ґушка — українська майстерня виробів з вовни.\nНаше виробництво знаходиться у гірському місті Косів, Івано-Франківської області. Ми виготовляємо тепло для дому — вовняні ліжники, доріжки, килими, пуфи, одяг. \n\nҐушка — це спроба зберегти, переосмислити традиції та дозволити до них доторкнутися.",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 78,
        "brand_name": "ФОП Шнуров Дмитро Ігорович",
        "website": "https://www.etsy.com/shop/World3dprint",
        "location": "Запорізька область",
        "main_product": "Іграшки",
        "about": "Молодий український бренд, який несе технології 3д друку у маси. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 81,
        "brand_name": "ROCK FRONT",
        "website": "https://www.rockfront.com.ua/",
        "location": "Кіровоградська область",
        "main_product": "Спортивне спорядження",
        "about": "ROCK FRONT – це молодий український бренд туристичного спорядження і одягу для активного відпочинку, започаткований командою мандрівників. \nУ спальниках, одязі і квілтах використовується натуральний українським гусячий пух. \nРозроблені і виготовлені в Україні.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 109,
        "brand_name": "OldTownLeather",
        "website": "https://www.etsy.com/shop/OldTownLeather?ref=seller-platform-mcnav",
        "location": "Вінницька область",
        "main_product": "Одяг або взуття",
        "about": "Компанія займається виробництвом товарів зі шкіри (сумки, гаманці, косметички, ремені). Компанія OldTownLeather була заснована у 2019 році. Вже протягом трьох років створюємо стильні і зручні речі для наших клієнтів з усього світу. Експортуємо нашу продукцію у понад 50 країн світу",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 119,
        "brand_name": "Busybee",
        "website": "https://www.busybee.if.ua",
        "location": "Івано-Франківська область",
        "main_product": "Іграшки",
        "about": "Сімейна майстерня з виготовлення дитячих розвиваючих дошок - бізібордів. Працюємо вже третій рік задля створення екологічної іграшки з дерева як альтернативи іграшкам з дешевого пластику.",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 141,
        "brand_name": "SHKOURA",
        "website": "https://www.shkoura.com",
        "location": "Київська область",
        "main_product": "Одяг або взуття",
        "about": "Shkoura - це український бренд одягу виробів з вовни та льону. Це так співпало, але \"у колекціях використовуємо овечу вовну, вироблену в українських Карпатах\" за адресою виробництва.\n\nМи робимо актуальні речи за допомогою старовинних технологій.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 154,
        "brand_name": "FAMILIA",
        "website": "https://famili.com.ua/",
        "location": "м. Київ",
        "main_product": "Прикраси",
        "about": "У маленькій майстерні створюються прикраси.\nНатуральні камені у вигляді улюблених кольє і браслетів зроблених з любов'ю і натхненням будуть зберігати вашу любов і пам'ять\nВаші бажання FAMILIA втілить в витончені індивідуальні прикраси які ви будете любити і зможете передавати з покоління в покоління",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 191,
        "brand_name": "Gnizdo",
        "website": "https://gnizdo.com/",
        "location": "Харківська область",
        "main_product": "Декор для дому",
        "about": "GNIZDO -  це український sustainable бренд текстилю для дому та одягу з натурального льону.\nВже понад 4 роки ми шиємо лляні речі у мінімалістичному стилі поза модою та часом.  Популяризуємо український продукт, використовуємо тільки натуральні матеріали, надихаємось культурою та автентикою України.",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 214,
        "brand_name": "ODEAS",
        "website": "https://www.etsy.com/shop/ODEAStoys?ref=seller-platform-mcnav",
        "location": "Житомирська область",
        "main_product": "Іграшки",
        "about": "Ми створюємо унікальні і цікаві іграшки та меблі для малечі. Наша місія – допомогти батькам створити для своїх малюків лише якісне, естетично красиве та здорове середовище, використовуючи меблі та іграшки, яке сприятиме всебічному розвитку дітей.",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 246,
        "brand_name": "Доставка Счастья, EnjoyPoster",
        "website": "https://dostavka-schastya.com/",
        "location": "Харківська область",
        "main_product": "Декор для дому",
        "about": "Доставка щастя - магазин подарунків та картин. Бренд заснований у 2017 році. За цей час ми надіслали понад 10 тис подарунків. Маємо власне невелике виробництво. \nEnjoyPoster - бренд картин для Європи та США. Заснований під час війни.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 261,
        "brand_name": "YAPPI",
        "website": "https://www.yappishop.com",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Трикотаж с креативними принтами",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 272,
        "brand_name": "Should Buy Wood",
        "website": "https://shouldbuywood.crafta.ua",
        "location": "Закарпатська область",
        "main_product": "Декор для дому",
        "about": "Should Buy Wood — це сімейна справа з виготовлення власних дизайнерських виробів на своєму міні-виробництві. Дзеркала для вітальні, спеційниці для кухні, чи, навіть, скриньки для обручок — все виготовлено з якісної української деревини та з частинкою власного серця.",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 280,
        "brand_name": "Вема Кідс",
        "website": "https://vemakids.shop/katalog/inklyuzyvne-obladnannya/",
        "location": "Київська область",
        "main_product": "Меблі",
        "about": "Ми команда фахівців, яка виробляє і комплексно оснащує, відповідно до сучасних стандартів і технологій: сенсорні кімнати, кабінети ЛФК та спортивні зали, навчальні класи, рекреації, ресурсні кімнати, інклюзивні дитячі майданчики.\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 321,
        "brand_name": "Noisy Wood ",
        "website": "https://www.etsy.com/shop/NoisyWood",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Українська майстерня з виготовлення деревьяних музичних інструментів ручної роботи Noisy Wood",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 360,
        "brand_name": "Lamantin",
        "website": "https://lamantin-store.com",
        "location": "Закарпатська область",
        "main_product": "Меблі",
        "about": "Затишні колиски та товари для догляду за дітьми. Локальні традиції та техніки виготовлення у сучасному стилі. Сплетено із гілочок лози закарпатськими майстрами та доповнено підставкою-качалкою з дуба. Локальні традиції та техніки виготовлення у сучасному стилі",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 364,
        "brand_name": "Zoozoo_forkids",
        "website": "https://instagram.com/zoozoo_forkids?igshid=NjY2NjE5MzQ=",
        "location": "Чернігівська область",
        "main_product": "Одяг або взуття",
        "about": "Zoozoo_forkids - український виробник дитячої банної продукції. Наші вироби створюють настрій для малечі та батьків. Ми, насамперед , за якісний сервіс , створюємо затишну сім’ю з користувачами #махрушкізверюшкі 🇺🇦",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 368,
        "brand_name": "HappyWickerToys",
        "website": "https://www.etsy.com/shop/HappyWickerToys?ref=seller-platform-mcnav",
        "location": "Херсонська область",
        "main_product": "Іграшки",
        "about": "HappyWickerToys - український бренд плетених дитячих іграшок та декору для дому. Я мрію та вірю, що нашими іграшками будуть задоволені всі. Їх навіть будуть передавати у спадок своїм дітям.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 404,
        "brand_name": "Toys from Mel",
        "website": "https://www.etsy.com/shop/ToysFromMel",
        "location": "м. Київ",
        "main_product": "Іграшки",
        "about": "Toys from Mel - український бренд, який виробляє декор для дитячих кімнат. Серед асортименту виробів переважають мобілі та підвіски з фетру. Всі вироби на 100% відшиваються вручну індивідуально під клієнта. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 408,
        "brand_name": "Infancy",
        "website": "https://infancy.com.ua",
        "location": "Луганська область",
        "main_product": "Іграшки",
        "about": "Сімейний бізнес з виробництва дитячих гойдалок та речей інтерьєру.\nПрацюємо 5 років.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 459,
        "brand_name": "KinderFrau",
        "website": "https://www.kinderfrau.store/uk/",
        "location": "Черкаська область",
        "main_product": "Інше",
        "about": "Kinderfrau - бренд, який довів всьому світу що мама може спати вночі не прокидаючись.  Ми виробники унікальних спальних мішків, що використовують з перших днів життя. Спальник зберігає тепло дитини, не сковує рухів і дарує відчуття комфорту наче в маминих обіймах і буде завжди з тобою.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 473,
        "brand_name": "BYKET",
        "website": "https://www.etsy.com/shop/ByketBags",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "BYKET це маленький сімейний бізнес із Харкова. Ми виготовляємо лляні аксесуари, такі як: сумки, гаманці, косметички з вишивкою та малюнками ручної роботи. Все розпочалося з залишків бабусиної лляної тканини та ниток для вишивання. А сьогодні нашими виробами користуються по всьому світу. :)",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 578,
        "brand_name": "Bruá ",
        "website": "http://brua.store/",
        "location": "Львівська область",
        "main_product": "Прикраси",
        "about": "Bruá - український нішевий бренд ювелірних виробів. Наша ціль популяризувати українське мистецтво та дизайн в світі. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 601,
        "brand_name": "LIGNO Group",
        "website": "https://ligno.com.ua/uk/",
        "location": "Львівська область",
        "main_product": "Декор для дому",
        "about": "LIGNO – бренд виробів з дерева. Ми прагнемо розвивати український предметний дизайн та віднайти його автентичні риси. Об'єднавши зусилля найкращих українських предметних дизайнерів і виробників ми впевнено розширюємо власну лінійку виробів з дерева та популяризуємо український дизайн в світі.",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 645,
        "brand_name": "Decor Forever",
        "website": "https://decor4ever.shop/",
        "location": "Харківська область",
        "main_product": "Декор для дому",
        "about": "DECOR Forever - це виробнича майстерня індивідуального об'ємного декору. Створюємо креативну обстановку для бізнесів, заходів та вдома.\nВиготовляємо life size скульптури, динозаврів, скам'янілості музейної якості та ексклюзивний інтер'єрний декор. На сьогодні наш декор вже у 12 країнах світу!",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 657,
        "brand_name": "Bright Berries",
        "website": "https://www.instagram.com/bright_berries/",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Bright Berries - це український стильний та якісний дитячий одяг. Кожна модель нашого одягу до найменшої дрібнички виробляеться з любовʼю до діточок, саме вони є нашим натхненням. \nМи навмисно не використовуємо надлишкове пакування кожної одиниці одягу, задля збереження довколишньго середовища.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 663,
        "brand_name": "Legna Organics",
        "website": "https://instagram.com/legnacosmetics?igshid=YmMyMTA2M2Y=",
        "location": "Харківська область",
        "main_product": "Косметика",
        "about": "Я Оленка,Чарівниця, яка здійснює мрії Жінок про здорову шкіру. А саме, створюю сучасну фізіологічну Косметика, направлену на відновлення захисного бар’єру шкіри.\nЯ Мрійниця і  Алхімік, і так з’явилась LEGNA , принципи якої: чесність,дієвість,простота,фізіологічність,усвідомлене споживання",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 664,
        "brand_name": "OnlyCraft",
        "website": "https://craftua.art/",
        "location": "Дніпровська область",
        "main_product": "Декор для дому",
        "about": "OnlyCraft- український бренд який виробляє дерев‘яні мапи світу та України з підсвічуванням та без. Вже більше року ми вносимо затишок у домівки родин України та всього світу.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 671,
        "brand_name": "Горіхові пасти Nuts Story ",
        "website": "https://instagram.com/nuts_story_?igshid=YmMyMTA2M2Y=",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "Nuts Story – сімейна майстерня натуральних арахісових та горіхових паст. Більше 3 років доводимо, що корисне може бути смачним. Продукція завжди свіжа, бо готується маленькими партіями, з відбірних горішків, без білого цукру, олії та шкідливих домішок. Безпечний склад для малюків та дорослих.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 815,
        "brand_name": "Balabanoff Leather Goods",
        "website": "https://balabanoff.com",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "Виробництво та продаж аксесуарів зі шкіри (гаманці, ремені, сумки, годинникові ремінці тощо) високої якості та конкурентні на світовому ринку. Ми робимо особливий акцент на використанні американської шкіри Horween та найкращі зразки української та італійської шкіри. Маємо клієнтів з понад 50 країн. ",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 835,
        "brand_name": "Uf.Bee",
        "website": "https://www.uf.in.ua",
        "location": "Київська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "Uf.Bee — це виробництво вощанок, багаторазої обгортки для продуктів харчування, що замінює харчову плівку та інший безглуздий одноразовий пластик. Наша місія — зробити так, щоб доба підкорення Марсу була plastic-free тут, на Землі. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 882,
        "brand_name": "Luff boutique ",
        "website": "https://luff.com.ua/",
        "location": "Харківська область",
        "main_product": "Косметика",
        "about": "Luff- інноваційний бренд української косметики, наша міссія це професійні засоби з найкращої світової сировини, які повинні бути доступними кожній. В кожному аспекті нашої косметики, ми прагнемо розвиватися та вдосконалюватись. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 898,
        "brand_name": "Noble Pet",
        "website": "https://noblepet.com.ua",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "Noble Pet - ми створюємо затишок для домашніх улюбленців, виготовляючи стильні лежанки, а також турбуємося про них під час прогулянок, створюючи зручний, практичний та трендовий одяг з якісних матеріалів, який не залишить байдужим не тільки власника, але і всіх перехожих.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 900,
        "brand_name": "BE WOODY",
        "website": "https://prom.ua/c3607626-woody.html",
        "location": "Харківська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "BE WOODY - український виробник торцевих дошок для кухні. Нині є лідерами у даній ніші на внутрішньому ринку. Працюємо вже більше трьох років, нашу продукцію можна побачити у різних куточках світу, навіть Австралії. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 920,
        "brand_name": "ТОВ \"ПРОМОРГАНІК\"",
        "website": "https://promorganik.prom.ua/product_list",
        "location": "Дніпровська область",
        "main_product": "Інше",
        "about": "ТОВ \"ПРОМОРГАНІК\" - український виробник господарського мила, понад п'яти років ми задовольняємо потреби споживачів якісною продукцією, виробленою із натуральної сировини.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 960,
        "brand_name": "NicePeopleWorkshop",
        "website": "https://www.etsy.com/shop/NicePeopleWorkshop?ref=seller-platform-mcnav",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Наш бренд PetSo - створюємо товари для домашніх тварин: будиночки, лежанки, годівниці тощо",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 978,
        "brand_name": "BEAUTYWALLS ",
        "website": "https://beautywalls.com/",
        "location": "Чернігівська область",
        "main_product": "Декор для дому",
        "about": "BEAUTYWALLS - український бренд гіпсових 3D стінових панелей для внутрішньої оздоблення стін (екслюзивний, запатентований продукт, cертифікат якості CE).\nЛідер на внутрішньому ринку в своєму сегменті. Експорт до Великобританії, Бельгії, Литви,Польщі, Словаччини, Республіки Кіпр.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1017,
        "brand_name": "FunEraKids",
        "website": "https://funerakids.com.ua ",
        "location": "Чернігівська область",
        "main_product": "Меблі",
        "about": "FunEra - український виробник дитячих меблів та килимків для щасливого дитинства.  Сенс Меблів у плеканні самостійності малюка до пізнання світу. Ми використовуємо екологічні матеріали, європейські фарби та гарантуємо якість продукту.\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1025,
        "brand_name": "Pranuka ",
        "website": "https://pranuka.com/",
        "location": "Хмельницька область",
        "main_product": "Косметика",
        "about": "Pranuka - натуральна косметика для функціонального догляду за шкірою. Косметика створюю я (Валерія), косметичний хімік з вищою європейською освітою та колишня викладачка польського ВУЗ-у. 3 роки тому я повернулася з метушливої Варшави у рідний Волочиськ, щоб створювати Косметика для Українок.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1029,
        "brand_name": "Staritska maysternya",
        "website": "https://www.staritska.com/en/shop ",
        "location": "Житомирська область",
        "main_product": "Декор для дому",
        "about": "Дизайнерські меблі та предмети декору. Лаконічні форми, скульптурність, правдиві матеріали, ручна робота. Поєднання сучасних і переосмислення традиційних технологій, recycling. Ми виготовляємо серійні меблі тиражами до 200 екземплярів і унікальні предмети декору, арт. Основний склад-10 майстрів. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1039,
        "brand_name": "Bark&Go",
        "website": "https://barkandgo.com.ua/",
        "location": "Львівська область",
        "main_product": "Одяг або взуття",
        "about": "Ми виробництво одягу та аксесуарів для собак та їх господарів. Горимо своєю справою, плануємо розвиватися та розширювати виробництво в Україні.",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 1076,
        "brand_name": "JaneandMery ( Джейн та Мері )",
        "website": "https://www.etsy.com/shop/JaneandMery",
        "location": "Закарпатська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "Jane and Mery це український бренд який одночасно займається виробництвом посуду з порцеляни та виробів з видутого - \"гутного\" скла. Керамікою займатись ми почали з дитинства, так це є родинною традицією, яка зараз естетично поєднується з гутнім склом в наших виробах.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1148,
        "brand_name": "CherryCoolToys ",
        "website": "https://www.etsy.com/shop/CherryCoolToys?ref=seller-platform-mcnav",
        "location": "Сумська область",
        "main_product": "Іграшки",
        "about": "В минулому - медична сестра. Майже три роки я виготовляю іграшки ручної роботи. Для мене важливе місце займає якість іграшки.Починаючи з симетрично пришитих ручок і ніжок, до , звісно, матеріалів.Тому купую не дешеві,а такі, з яких і своєму синові виготовляю іграшки.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1204,
        "brand_name": "VESNA",
        "website": "https://vesna.care/uk/",
        "location": "Київська область",
        "main_product": "Косметика",
        "about": "VESNA - виробник рослинної косметики, який вже 7 років доводить ефективність природних компонентів. Завдяки нашій авторській рецептурі косметика є дієвою та має гіпоалергенний склад. ",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 1205,
        "brand_name": "Espacio",
        "website": "https://www.instagram.com/espacio.ua/",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Espacio - бренд взуття та сумок, вже 4 роки ми робимо взуття, яке має якість, не гіршу від італійського взуття. Маємо власне виробництво. Великий акцент робимо на якість нашої продукції, тому ставимося прискіпливо до кожної дрібнички☺️",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1215,
        "brand_name": "Master'sArtWood",
        "website": "https://etsy.com/shop/mastersartwood ",
        "location": "Запорізька область",
        "main_product": "Декор для дому",
        "about": "Моя майстерня займається виготовленням якісних виробів з натуральної деревини. Працюю по всьму світу - мої настінні дзеркала можна побачити від Австралії до Аляски, та у кожному штаті США.  На деяку свою продукцію маю патент. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1225,
        "brand_name": "ФОП Пчельникова Т.Л.",
        "website": "https://www.etsy.com/shop/BeeTWallArtPrints?ref=seller-platform-mcnav\nhttps://www.etsy.com/shop/BeeTArtistGallery?ref=seller-platform-mcnav\n",
        "location": "м. Київ",
        "main_product": "Декор для дому",
        "about": "Бренд картин ручної роботи та домашнього текстилю з льону та бавовни з принтами ціх картин. Ми показуємо світу, що українці. незважаючи на страхіття війни продовжуюсь творити красоту та мріють про мир та добро. Ще я проводжу майстер класи по живопису для дітей та дорослих в арендованій студії",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1270,
        "brand_name": "LOL&POP ",
        "website": "https://lolandpop.ua",
        "location": "м. Київ",
        "main_product": "Інше",
        "about": "LOL&POP - це сімейна мануфактура натуральних солодощів ручної роботи. 9 років назад я створила LOL&POP, керуючись бажанням робити незвичайні, натуральні цукерки, в першу чергу для своєї доньки. Але ця ідея виявилась близької багатьом людям, як в Україні, так і за її межами. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1279,
        "brand_name": "POLIVIK",
        "website": "https://instagram.com/polivik.design?igshid=YmMyMTA2M2Y=",
        "location": "м. Київ",
        "main_product": "Одяг або взуття",
        "about": "Ми український свідомий бренд жіночого одягу у стилі «casual» з етнічними мотивами у сучасному дизайні.   Вже понад п‘ять років створюємо одяг для українських жінок. У колекціях використовуємо екологічні матеріали. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1341,
        "brand_name": "Space•N",
        "website": "https://instagram.com/spacen_wear?igshid=YmMyMTA2M2Y=",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Одяг для людей та про людей. Ми через одяг бажаємо звернути увагу на всі шари населення. Робили  багато інклюзивних замовлень. Бажаємо стерти усі стереотипи про те, що таке красиве. Тому наш лозунг : we are children of the world. Ми всі діти цього миру 🙏🏽",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1396,
        "brand_name": "Another Face Craft",
        "website": "https://www.etsy.com/shop/AnotherFaceCraft",
        "location": "Дніпровська область",
        "main_product": "Інше",
        "about": "Я власник майстерні з виготовлення карнавальних масок різноманітних персонажів з фільмів комп'ютерних ігор, та інших. Вже п'ятий рік намагаюсь вдосконалюватись та приносити задоволеня своїм клієтам, 85% із яких знаходяться в США.",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 1411,
        "brand_name": "Wood Panda",
        "website": "https://rozetka.com.ua/seller/woodpanda/goods/",
        "location": "Івано-Франківська область",
        "main_product": "Декор для дому",
        "about": "Ми - виробники декору та подарунків з дерева. Все виготовляємо власноруч, автоматизовуємо процеси на роботизованому обладнанні. Активно продаємо на українських та закордонних маркетплейсах. \n\nВиготовляємо речі з душею. Таких речей ви ніколи не знайдете в звичайних магазинах. У нас тільки ексклюзив!",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1485,
        "brand_name": "ФОП Степнов Михайло Ігорович",
        "website": "https://www.instagram.com/zhuzhyk_ua",
        "location": "Миколаївська область",
        "main_product": "Іграшки",
        "about": "Нашому виробництву лише півтора роки, а заявку на реєстрацію бренду ми подали півроку тому.\nПівтора роки тому ми працювали з дружиною у квартирі - а до початку повномасштабної війни наша команда налічувала вісім осіб \nМи відшиваємо дитячі вігвами,  текстильні товари, іграшки, подушки, піжамки тощо.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1491,
        "brand_name": "Ineo Products",
        "website": "https://www.ineo.ltd/",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "Крафтовий виробник корисних солодощів без цукру. Віримо зо зможемо змінити світ на краще роблячи якісну продукцію з любов’ю.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1598,
        "brand_name": "saikoecostore",
        "website": "https://www.etsy.com/shop/SaikoEcoStore?ref=simple-shop-header-name&listing_id=654888712",
        "location": "Херсонська область",
        "main_product": "Одяг або взуття",
        "about": "SaikoEcoStore це український бренд який виготовляє жіночий одяг з льону у дуже маленькій майстерні, де кожен виріб збирається самостійно у ручну.\nНаша ціль: використовувати натуральну сировину яка не шкодить нашій планеті та буде довго служити своєму власнику.\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1601,
        "brand_name": "ТОВ\"САНФІЛЛ\"",
        "website": "http://Sunfill.ua",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "ТОВ \"САНФІЛЛ\" - це компанія, яка виробляє корисні та  натуральні продукти ( хлібці  та цукерки із сухофруктів та горіхів).\nБез додавання цукру, шкідливих інгредієнтів, використання високих температур, щоб максимально зберегти всі  корисні властивості.\n Наша цінність - ТУРБОТА ПРО ЛЮДЕЙ!\n",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 1667,
        "brand_name": "NESAMOVYTO",
        "website": "https://nesamovyto.com",
        "location": "м. Київ",
        "main_product": "Одяг або взуття",
        "about": "Український бренд авторських аксесуарів, натхненний життям у всіх його проявах. Бренд, що черпає ідеї у глибині таємниць природи, у наповненому смислами мистецтві, в унікальному людському єстві.Ми переосмислюємо культурне, природне, людське, втілюючи вічні цінності в ілюстраціях, принтах і дизайнах.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1682,
        "brand_name": "TaleAR",
        "website": "https://talear.club",
        "location": "Вінницька область",
        "main_product": "Іграшки",
        "about": "Я переможець всеукраїнської олімпіади із педагогіки вирішив створювати розвиваючі методичні іграшки, які поєднані із навчанням дітей (таблиця множення, логопедія, вивчення англійских слів, тощо). Наші вироби мають авторські права та користуються популярністю. 80% виробів свторюємо власними руками.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1713,
        "brand_name": "ТОВ \"ОРІГА\"",
        "website": "https://origa.ua/",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "ORIGA - український бренд одягу . Ми розробляємо одяг, який допомагає сконцентруватися на своїй жіночності, любові до миру та краси! Наші колекції оновлюються. Ми співпрацюємо з перевіреними українськими постачальниками, які використовують стійкі, гіпоалергенні барвники та нитки високої якості.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1756,
        "brand_name": "Hapstone",
        "website": "https://hapstone.pro",
        "location": "Сумська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "Hapstone - український бренд пристроїв для заточування ножів. \nНайкращі матеріали, українські генії інженерної думки та топовий контроль якості дозволяє голосно заявляти про українські товари усьому світу",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1761,
        "brand_name": "NADELPOSHEL",
        "website": "https://nadelposhel.com.ua",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Бренд українського оверсайз одягу, який за три роки вже знають майже у кожному куточку нашої краіни. Наш сервіс та товар отримав високу оцінку серед наших клієнтів. Понад 6000 задоволених та понад 1000 постійних клієнтів за 2,5 роки існування бренду. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1778,
        "brand_name": "veter models",
        "website": "https://vetermodels.com/",
        "location": "Дніпровська область",
        "main_product": "Іграшки",
        "about": "Вас вітає Український бренд \"veter models\", це молода команда ентузіастів і любителів моделізму з Кривого Рогу - саме тут ми власноруч проектуємо і виробляємо унікальні моделі  – гібриди з дерева і пластика. У нас Кожна розробка – це плід багатомісячної і праці та інженерних пошуків.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1803,
        "brand_name": "Tatoy",
        "website": "https://tatoy.com.ua",
        "location": "Дніпровська область",
        "main_product": "Іграшки",
        "about": "Ми сімейне виробництво дитячіх еко іграшок та меблів. Кожний виріб зроблений з любовю ніби сам тато зробив його для своїх діточок. Віримо що українські бренди можуть завоювати світ. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1822,
        "brand_name": "BE OM design",
        "website": "https://beomdesign.com.ua/",
        "location": "Одеська область",
        "main_product": "Одяг або взуття",
        "about": "BE OM - мінімалістичний бренд для повсякденного життя із власним виробництвом в Одесі.\nМи створюємо одяг, доступний кожному, з якісних, переважно натуральних матеріалів",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1852,
        "brand_name": "Bee Granola",
        "website": "https://beegranola.com.ua",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Наша компанія займається виробом граноли це запечені вівсяні пластівці з медом, в залежності від смаку додаємо фрукти, та горішки. Просто залий молоком, йогуртом та смакуй. \nМи на ринку вже 5 років, останні пів року працювали над експортом до європи, це наша головна мета, вийти на західні ринки. \n\n\n",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 1864,
        "brand_name": "Formochki.3d",
        "website": "https://bez3d.net",
        "location": "Донецька область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "Formochki.3d - виробник інструментів для кондитерів(вирубки, трафарети, насадки для зефіру, упаковка з фанери, силіконові молди). Наша мета - показати, що навіть у маленькому шахтарському містечку можна побудувати свою справу з використанням сучасних технологій(3д принтер, фрезерні та лазерні ЧПУ)",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 1896,
        "brand_name": "Anzy Home",
        "website": "https://anzyhome.com.ua/",
        "location": "Харківська область",
        "main_product": "Декор для дому",
        "about": "Anzy Home — це бренд в'язаного декору. В нашій майстерні досвідчені майстрині вручну створюють затишні предмети для облаштування дому - в'язані пуфи, килими, кошики, декор та меблі для дитячої кімнати, тощо. Ми активно експортуємо, а також співправюємо з магазинами та дизайнерами.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2041,
        "brand_name": "ТОВ Медик Ведмедик",
        "website": "https://www.medyk-vedmedyk.ua/",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Медик Ведмедик - крафтовий виробник меду заснований в 2012. Ми довели, що мед можна продвигати як вишуканий продукт, який має свої відмінності в залежності від місця збору та кліматичних умов місцевості як у найкращіх винах. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2073,
        "brand_name": "Zhygun Herbs",
        "website": "https://zhygun-herbs.com/",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Zhygun Herbs - це функціональні трав‘яні чаї з супер силою та неймовірним смаком. У кожного чаю свої стиль, сила, аромат, смак і післясмак. Це революція на ринку чаю! При цьому ми зберігаємо традиції поєднання і збору трав.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2081,
        "brand_name": "DARVIN",
        "website": "https://www.instagram.com/darvin.dog.shop/",
        "location": "Харківська область",
        "main_product": "Інше",
        "about": "DARVIN - амуніція заради комфорту. Ми не просто виготовляємо товар, а просуваємо ідею гуманного поводження із тваринами. Другий рік поспіль збільшуємо оберти та асортимент: анатомічні шлейки, паски безпеки, повідки, туристичні килимки, лежанки тощо. Принципи: гуманність, екологічність та гарантія.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2157,
        "brand_name": "Woodstuff",
        "website": "https://woodstuff.com.ua/",
        "location": "м. Київ",
        "main_product": "Посуд та кухонні пристосування",
        "about": "WOODSTUFF – український сімейний бренд сучасного дерев’яного посуду, що поєднує природу та дизайн, технології та традиції деревообробки.   \nМета – покращувати життя людей вдома та зберігати традиції. \nНаші фішки: щорічна висадка дерев та безвідходне виробництво",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2208,
        "brand_name": "ФОП Іващенко Сергій Борисович",
        "website": "https://www.etsy.com/shop/CarvingSM?ref=seller-platform-mcnav\n\n",
        "location": "Чернігівська область",
        "main_product": "Декор для дому",
        "about": "З 2019 року наша сім’я займається виготовленням різних речей вирізблених з дерева. Це і ікони, і розп’яття, та інші речі декору, які приносять затишок в будинки по всьому світу. При виготовлеені своєї продукції використовуємо тільки якісну деревину та натуральні барвники та масла.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2302,
        "brand_name": "OSIAZHNYI",
        "website": "https://www.instagram.com/osiazhnyi",
        "location": "Миколаївська область",
        "main_product": "Спортивне спорядження",
        "about": "OSIAZHNYI - це український бренд спортивних пов'язок для бігу, воркауту, фітнесу тощо. Ми хочемо бачити українське суспільство активним, тому розробили яскраві та якісні пов'язки, що не поступаються продукції світових брендів. Ми й самі бігаємо у них напівмарафони, адже впевнені у кожному шві.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2527,
        "brand_name": "HEDONIC",
        "website": "https://www.instagram.com/hedonic_beauty/",
        "location": "Харківська область",
        "main_product": "Косметика",
        "about": "Hedonic - український виробник косметичних засобів для укладки і догляду за бровами та віями. Рецептура розроблена кращими українськими технологами, сировина від світових брендів, сертифіковане виробництво в Харкові - все це дає високу якість та помірну ціну наших продуктів.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2530,
        "brand_name": "ZeinTex",
        "website": "https://zeintex.prom.ua/",
        "location": "Київська область",
        "main_product": "Декор для дому",
        "about": "Бренд починався із пошиття постільної білизни на замовлення вдома, на побутовій машинці.\nЗараз наші вироби гріють людей в підвалах по всій країні. Для пошиву текстилю використовуємо лише сертифіковані тканини зі 100% натуральним складом, відходи від виробництва передаємо в школи для гуртків праці. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2531,
        "brand_name": "florella",
        "website": "https://chernigov.florella.com.ua/index.php?route=product/category&path=82",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Укриїнській бренд який поділяєтся на роздрібну торгивлю квітами та вирощування квітів у теплицях",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2559,
        "brand_name": "Kazvan Hats",
        "website": "https://www.etsy.com/shop/KazvanHats",
        "location": "Рівненська область",
        "main_product": "Одяг або взуття",
        "about": "Kazvа́n - це український sustainable бренд головних уборів та аксесуарів. Ідея: допомагати людям розкривати унікальність через зовніншній образ. А також створювати індивідуальні вироби ручної роботи з найменшою шкодою для довкілля. У світ ми транслюєму українську культуру душевності та відкритості.\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 2639,
        "brand_name": "Dreamer Leather",
        "website": "https://www.etsy.com/shop/Dreamerleathergoods?ref=seller-platform-mcnav",
        "location": "Донецька область",
        "main_product": "Інше",
        "about": "Dreamer - це бренд крафтого виробництва гаманців, сумок та інших виробів за натуральної української шкіри та шиємо наші виробу вручну. \nМи хочемо, щоб  Україна асоціювалася  з якістю та надійністю, а тому нашою метою є поширення у світі українських виробів найвищого ґатунку.\n",
        "diia": true,
        "ukrposhta": false
    },
    {
        "id": 2792,
        "brand_name": "Dolce-Club",
        "website": "https://dolceclubua.taplink.ws/",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Крафтовий виробник крем-меду з сублімованими фруктами, та горішків з медом - з власної пасіки 💛💙 ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3028,
        "brand_name": "Жу-жуshop",
        "website": "https://djujushop.com.ua",
        "location": "Чернігівська область",
        "main_product": "Інше",
        "about": "Жу-жу shop - виробник натурального шоколаду з медом із власної пасіки. Цінуємо здоров'я кожної людини, виробляємо продукцію із якісних інгредієнтів.\nВ асортименті маємо шоколад: \n• молочний (без інгредієнтів тваринного походження)\n• з різними начинками\n• з додаванням сушених ягід власного збору\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3053,
        "brand_name": "Here & Now",
        "website": "https://www.etsy.com/shop/HereAndNowPottery",
        "location": "Київська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "«Here & Now» ‒ це перший бренд студійної екологічно-сталої кераміки в Україні. Ми створюємо авторський глиняний посуд та декор, сертифіковані за стандартом Clean Green Ceramics. \n\nКераміка від «Here & Now» унікальна, натхненна природою і зручна у щоденному використанні. \n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3062,
        "brand_name": "Оne by Оne",
        "website": "https://www.onebyone.com.ua/",
        "location": "Кіровоградська область",
        "main_product": "Одяг або взуття",
        "about": "історія почалася в  2015 році з сімейної мрії тоді і народився український бренд One by One. Вже понад 7 років ми працюємо з розвитку бренду та сучасних традицій України, створюємо стильний та комфортний одяг з урахуванням побажань україночок, продуктом такого підходу - є захоплення з-за кордону ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3095,
        "brand_name": "ТМ Ukono (конопляний текстиль)",
        "website": "https://ukono.store/",
        "location": "Сумська область",
        "main_product": "Декор для дому",
        "about": "UKONO - невеликий український бренд, який займається продажем подушок, ковдр та інших виробів із конопляних матеріалів.\n\nВиробництво знаходиться в місті Суми, і виконується із конопляних матеріалів, які вирощені на території рідної Сумщини.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3194,
        "brand_name": "Видавництво \"Калейдоскоп\"",
        "website": "https://www.kaleidoskope.shop",
        "location": "м. Київ",
        "main_product": "Іграшки",
        "about": "Видавництво \"Калейдоскоп\" - український бренд, який створює сучасні розвивальні та навчальні ігри. Розробляючи ігри, ми аналізуємо освітні програми, враховуємо вікові особливості дітей. Як результат - діти навчаються цікаво, стають ерудованішими, менше часу приділяють гаджетам, більше - спілкуванню.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3257,
        "brand_name": "WasBorn",
        "website": "https://wasborn.in.ua/",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "WasBorn - це майстерня виробів зі шкіри ручної роботи. Ми з Харкова, створюємо продукцію вже 5 років, і дуже цінуємо те, чим займаємось. Працюємо тільки з натуральною шкірою, робимо для людей, якісно, виважено, ретельно дотримуючись технологій, і не боїмося нових експериментів",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3266,
        "brand_name": "Бутік сучасної вишиванки",
        "website": "https://vyshyvanka.sumy.ua/",
        "location": "Сумська область",
        "main_product": "Одяг або взуття",
        "about": "Бутік сучасної вишиванки - це перш за все про цінності. Поєднання любові до своєї справи та клієнтів, повага до історії та популяризація України за її межами. Тільки натуральні льняні тканини, власні дизайни та якісні вироби - це наш девіз та кредо. \nМи віримо в те, що робимо!",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 3297,
        "brand_name": "Local Foodie",
        "website": "https://chorizo.com.ua/",
        "location": "Одеська область",
        "main_product": "Інше",
        "about": "Сімейна справа. Готуємо крафтові мʼясні делікатеси так, як фемреми в Середземноморʼї за традиціними рецептами з накращих локальних одеських продуктів. Готує одна людина, ми робимо ручинй ремісничий продукт. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3329,
        "brand_name": "Sovushka Little",
        "website": "https://www.etsy.com/shop/SovushkaLittle",
        "location": "Дніпровська область",
        "main_product": "Іграшки",
        "about": "Моя фірма виробляє тактильні дитячі книги, які сприяють розвитку дрібної моторики. Гнучкість вибору дає змогу покупцю адаптувати книгу під вік та інтереси свого малюка. Книги використовуються фахівцями при роботі з особливими дітками, та дорослими з деменцією. Продукція присутня в 43 країнах світу! ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3361,
        "brand_name": "швейна ф-ка «Mari I Poll”",
        "website": "https://mariipoll.com.ua/",
        "location": "Донецька область",
        "main_product": "Іграшки",
        "about": "Маріупольська швейна ф-ка «Mari I Poll”відкрилися завдяки грант. програмі Український донецький куркуль  у 2018 році. Ми отримали 500 тис. Грн. Виготовляємо м’які іграшки та подушки, випускаємо свою колекцію дитячого та жіночого одягу. На сьогодні я вклала у виробництво майже 3 млн. Грн. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3412,
        "brand_name": "Mamalish",
        "website": "https://instagram.com/mamalish_ua?igshid=YmMyMTA2M2Y=",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Mamalish-український виробник одягу для малюків від народження до 5років. Всі наши вироби ексклюзивні, так як прийти  нам малюють професійні, українські ілюстратори. А потом їх переносимо на тканину. Фарби та основа тканини безпечна для діток від народження. В нас власне виробництво в м.Харків. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 3758,
        "brand_name": "Jungle",
        "website": "https://www.instagram.com/jungle_ap/",
        "location": "Львівська область",
        "main_product": "Інше",
        "about": "Jungle - український бренд персоналізованих шкіряних аксесуарів. Всі аксесуари виготовляємо в майстерні у Львові. \nБренд Jungle на ринку вже 5 років.\nМи робимо персоналізовані аксесуари - це подарунки, які викликають ВАУ!\nБільше 35000 проданих аксесуарів у 50 країнах світу",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 4353,
        "brand_name": "Koza  Dereza Manufacture ",
        "website": "https://www.kozadereza.ua",
        "location": "Тернопільська область",
        "main_product": "Іграшки",
        "about": "Ми  виготовляємо етно одяг, іграшки, сувеніри та аксесуари, поєднуючи традиційні підходи, узори, кольори і сучасні тенденції дизайну та авторські екотехнології. \n   Все, що виходить з стін майстерні - виключно наш авторський контент. Використовуємо якісні екологічні матеріали. Тільки ручний розпис. ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 4371,
        "brand_name": "Plenergy",
        "website": "https://plenergy.ua",
        "location": "Миколаївська область",
        "main_product": "Спортивне спорядження",
        "about": "Ми Український бренд розумного спортивного інвентару. Розробляємо всі продукти саміб а иробляємо на власному виробництві в Одесі: турніки, бруси, шведські стінки. Ми офіційни постачальники Олімійської збірної України. Готуємо єкспортну партію турників на німецький Амазон, з надписом Made in Ukraine!",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 4374,
        "brand_name": "Майстерня39",
        "website": "https://m39.com.ua/",
        "location": "Чернівецька область",
        "main_product": "Декор для дому",
        "about": "М39 прикрашає виробами з дерева домівки і свята. З нами ви забудете про питання \"що подарувати?\", тому що асортимент широкий, а також є можливість внести персоналізацію. А такі речі не купиш у звичайному магазині! В нас понад 30000 задоволених клієнтів, адже кожний наш продукт робимо як для себе! ",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 4427,
        "brand_name": "Mr.Ober",
        "website": "https://instagram.com/mr.ober__cap?igshid=YmMyMTA2M2Y=",
        "location": "Харківська область",
        "main_product": "Одяг або взуття",
        "about": "Доброго дня!) \nСтворила бренд мр.обер\nШию кепки восьмиклинки(хуліганки) та продаю по Україні та закордон. Крім рф та рб ",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 4644,
        "brand_name": "Quiet Form",
        "website": "https://www.etsy.com/shop/quietform\n",
        "location": "Київська область",
        "main_product": "Посуд та кухонні пристосування",
        "about": "Quiet form-сімейний бренд керамічного посуду та декору ручної роботи. \nВкладаючи любов та енергію у в такі повсякденні речі, як ваза для квітів чи обідня тарілка, ми віримо, що спонукаємо святкувати життя у деталях побуту. Кожна тиха форма створена як символ любові, затишку та щирих історій.",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 4670,
        "brand_name": "ARX Mercatura",
        "website": "https://www.arx.biz",
        "location": "Полтавська область",
        "main_product": "Декор для дому",
        "about": "Виготовляємо товари для релігійних практик римських політеїстів; декоративні товари, пов'язані з Давнім Римом та Античністю. Асортимент включає авторські латунні статуетки Богів, дерев'яні алтарі і скриньки для дому, одяг, взуття та ювелірні вироби аутентичні щодо Давнього Риму та більше.",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 4712,
        "brand_name": "BusyAndFun",
        "website": "https://www.etsy.com/shop/BusyAndFun",
        "location": "Дніпровська область",
        "main_product": "Іграшки",
        "about": "Ми український виробник дерев'яних іграшок для розвитку дітей. Наші ексклюзивні сенсорні дошки з  LED-світлом допомагають розвивати дрібну моторику, логіку та креативність дітям у 40+ країнах. Особливо цінним є те, що нас також обирають батьки дітей з особливими потребами концентрації, аутизмом",
        "diia": false,
        "ukrposhta": true
    },
    {
        "id": 5230,
        "brand_name": "NOELED",
        "website": "https://www.noeled.store/uk/\n",
        "location": "Житомирська область",
        "main_product": "Інше",
        "about": "Ми першими в світі створили кришки на різьбі. Перенесли принцип різьби на натуральне дерево. Кришки мають 100% показник повторного використання та мінімальний екологічний слід. Також працюємо над поєднанням натуральних не стандартних матеріалів в дизайні упаковки.\n",
        "diia": false,
        "ukrposhta": false
    },
    {
        "id": 5398,
        "brand_name": "Ohra Home",
        "website": "https://ohrahome.com.ua/",
        "location": "Київська область",
        "main_product": "Декор для дому",
        "about": "Ми соціальний бренд домашнього текстилю Нашу постільну білизну створюють надзвичайні жінки-мами особливих дітей.А кошти від продажу йдуть на медичну допомогу дітям з інвалідністю. Ми створили навчальні курси та працевлаштовуємо мам.\nСаме тому Ohra Home робить дім затишнішим, а світ добрішим ❤️\n",
        "diia": true,
        "ukrposhta": false
    }
]

const WinnerIcon = () => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="12" fill="#F3CF21" />
    <path d="M18.5 28.25L11 19.25L18.5 22.25L29.75 11L18.5 28.25Z" fill="black" stroke="black" />
</svg>


const WinnerCard = styled.div`
    background: ${Colors.gray};
    padding:24px;
    flex-basis: 395px;
    flex-grow: 1;
    flex-shrink: 2;
    margin: 0 16px 16px 0

    `





const WinnerItem = ({ brand_name, diia, ukrposhta, location, main_product, about, website }) => {
    const [expandAbout, setExpandAbout] = React.useState(false)
    return (
        <WinnerCard>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >

                <WinnerIcon />
                <a href={website}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        "font-family": "TT Trailers",
                        color: "#000",
                        fontWeight: 700,
                        fontSize: 28,
                        marginLeft: 12
                    }}
                >{brand_name}</a>
            </div>
            {(diia || ukrposhta) && (
                <div style={{ margin: "12px 0 0 0" }}>
                    <Text.S small>Додаткові призи від {ukrposhta && <img style={{
                        width: "24px",
                        height: "24px",
                        margin: "0 0 -6px 0"
                    }} src={ukrposhtamini} />} {diia && ukrposhta && "та"} {diia && <img style={{
                        width: "24px",
                        height: "24px",
                        margin: "0 0 -6px 0"
                    }} src={diiamini} />} </Text.S>
                </div>
            )}
            <div style={{
                display: "flex",
                alignItems: "center",
                margin: "8px 0 2px 0"
            }}>
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 8 }}>
                    <g opacity="0.5">
                        <path d="M11.25 6C11.25 7.43998 10.6708 8.78975 9.6238 10.3189C8.8439 11.458 7.85004 12.6354 6.70756 13.989C6.47755 14.2615 6.24152 14.5412 6 14.8291C5.75848 14.5412 5.52245 14.2615 5.29244 13.989C4.14995 12.6354 3.1561 11.458 2.3762 10.3189C1.32918 8.78975 0.75 7.43998 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6Z" stroke="black" stroke-width="1.5" />
                        <circle cx="6" cy="6" r="2" fill="black" />
                    </g>
                </svg>
                <Text.S small light>

                    {location}&nbsp;•&nbsp;{main_product}
                </Text.S>


            </div>

            <Text.S small>
                {about.length > 149 && !expandAbout
                    ? <>
                        <div>
                            {about.substring(0, 149)}
                        </div>
                        <Text.S small underline light onClick={() => setExpandAbout(true)}>Детальніше</Text.S>
                    </>
                    : <div>{about}</div>}

            </Text.S>
        </WinnerCard>
    )
}

const Results = () => {


    const [search, setSearch] = React.useState("")
    const [region, setRegion] = React.useState("")
    const [industry, setIndustry] = React.useState("")
    const [fitlerUkrposhta, setFitlerUkrposhta] = React.useState(false)
    const [fitlerDiia, setFitlerDiia] = React.useState(false)

    const { width } = useWindowSize()
    const isMobile = width < 512

    const searchResults = winnersData.filter(({ brand_name, main_product, location, ukrposhta, diia }) =>
        brand_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        && (industry ? industry === main_product || industry === "Всі індустрії" : true)
        && (region ? region === location || region === "Всі області" : true)
        && (fitlerUkrposhta ? ukrposhta : true)
        && (fitlerDiia ? diia : true)
    )
    return (
        <div>
            <Seo title="Made in UA Support Project - Результати проєкту" />
            <Header />
            <div style={{ paddingTop: 144 }}></div>
            <Container>
                <Stack gap={24} align="center">
                    <h1 style={{
                        fontFamily: "TT Trailers",
                        fontWeight: 700,
                        fontSize: isMobile
                            ? 40
                            : 56
                    }} >Отримають підтримку</h1>


                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 -16px -16px 0"
                    }}>
                        <div style={{ flexGrow: 2, margin: "0 16px 16px 0" }}>

                            <TextArea
                                label="Пошук"
                                oneliner
                                name="search"
                                onChange={({ target: { value } }) => setSearch(value.trim())}
                                value={search}
                            />
                        </div>
                        <div style={{ flexGrow: 1, margin: "0 16px 0 0" }}>
                            <div style={{ width: "calc(50% - 8px)", marginRight: 16, display: "inline-block" }}>

                                <Select {...{
                                    label: "Область",
                                    value: region,
                                    options: regionOptions.map((v) => ({ text: v, value: v })),
                                    onChange: ({ target: { value } }) => setRegion(value.trim())
                                }} />
                            </div>


                            <div style={{ width: "calc(50% - 8px)", display: "inline-block" }}>

                                <Select {...{
                                    label: "Індустрія",
                                    value: industry,
                                    options: mainProductOptions.map((v) => ({ text: v, value: v })),
                                    onChange: ({ target: { value } }) => setIndustry(value.trim())
                                }} />
                            </div>
                        </div>



                    </div>


                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "8px"

                    }}>
                        <div style={{ margin: "0 24px 12px 0" }}>
                            <Checkbox label="Тільки переможці від Укрпошта" onChange={({ target: { checked } }) => setFitlerUkrposhta(checked)} value={fitlerUkrposhta} />
                        </div>
                        <div>

                            <Checkbox label="Тільки переможці від Дія" onChange={({ target: { checked } }) => setFitlerDiia(checked)} value={fitlerDiia} />
                        </div>

                    </div>


                    <CardContainer>
                        {searchResults.length > 0
                            ? searchResults.map((props) => (<WinnerItem {...props} />))
                            : <div>Не знайдено</div>}

                    </CardContainer>
                </Stack>
            </Container>
        </div>
    )
}
const CardContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
margin: 0 -16px 180px auto;

@media(max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
}

@media(max-width: 768px) {
    grid-template-columns: auto;
}
`
export default Results